import './Dashboard_.css';
import React, {useState} from 'react';
import Navbar from '../../components/navbar/Navbar-Dashboard';

import DashboardMenu from '../../components/DashboardMenu/DashboardMenu';
import DashboardBuyPanel from '../../components/DashboardBuyPanel/DashboardBuyPanel';

import Wallet from '../../components/Wallet/Wallet';
import BuySel from '../../components/BuySel/BuySel';
import BuySelList from '../../components/BuySel/BuySelList';
import Withdraw from '../../components/Withdraw/Withdraw';
import Deposit from '../../components/Deposit/Deposit';
import Referance from '../../components/Referance/Referance';

import Menu from '../../components/Menu/Menu-Dashboard'
const Dashboard = () => {

    const [Panel, setPanel] = useState('Wallet');

    const DashboardButtons = (PanelID) => {
        if(PanelID !==Panel){
            setPanel(PanelID);
        }
    };
    return(
        <div className='Dashboard'>
            <Navbar setPanel={setPanel}/>
            <Menu setPanel={setPanel}/>
            <div className='Dashboard-Content'>
                <div className='Dashboard-Left'>
                    <DashboardMenu DashboardButtons={DashboardButtons}/>
                </div>
                <div className='Dashboard-Center'>
                    {Panel ==='BuySel' &&<div className='Dashboard-Center-Panel active'>
                        <BuySel/>
                    </div>}
                    {Panel ==='Deposit' &&<div className='Dashboard-Center-Panel active'>
                        <Deposit/>
                    </div>}
                    {Panel ==='Withdraw' &&<div className='Dashboard-Center-Panel active'>
                        <Withdraw/>
                    </div>}
                    {Panel ==='Wallet' &&<div className='Dashboard-Center-Panel active'>
                        <Wallet setPanel={setPanel}/>
                    </div>}
                    {Panel ==='Referance' &&<div className='Dashboard-Center-Panel active'>
                        <Referance/>
                    </div>}
                </div>
                {Panel !=='BuySel' && <div className='Dashboard-Right'>
                    <DashboardBuyPanel />
                </div>}
                {Panel ==='BuySel' && <div className='Dashboard-Right'>
                    <BuySelList />
                </div>}
            </div>
            
        </div>
    )
}
export default Dashboard