import './Navbar.css';
import React, { useState,useEffect,useContext} from "react";
import {  Link as RouterLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import {Link } from 'react-scroll';
import { Degiskenler } from '../../Context/GlobalDegiskenler';
import Login from '../../components/Login/Login';
import LanguageIcon from '../Language/Language';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
const Navbar = () => {
  const {i18n, t} = useTranslation();
  const changeLanguage = (language) => {
      i18n.changeLanguage(language);
    };

  const location = useLocation();
  const {Menu, setMenu, Language,setLanguage,setRegister, setuserCode, setLogin, LoginStatus, AcilirVideo,showLogin, setShowLogin} = useContext(Degiskenler);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const registerValue = searchParams.get('Refferal');

    const timer = setTimeout(() => {
      if (registerValue !== null && !LoginStatus) {
        setShowLogin(true);
        setRegister(true);
        setLogin(false);
        setuserCode(registerValue);
        window.scrollTo(0, 0);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [location.search, LoginStatus, setLogin, setRegister, setShowLogin, setuserCode]);
  useEffect(() => {
    if (Menu || AcilirVideo) {
      document.body.style.overflow = 'hidden';
    }  else {
      document.body.style.overflow = 'auto';
    }
  }, [Menu, AcilirVideo]);

  useEffect(() => {
    if (!Menu) {
      document.body.style.overflow = showLogin ? 'hidden' : 'auto';
    }
   
  }, [showLogin, Menu]);


  const handleChange = selectedOption => {
    setLanguage(selectedOption.value);
    changeLanguage(selectedOption.value);
    localStorage.setItem('Language',selectedOption.value);
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      color: 'white', // Seçeneklerin yazı rengi
      backgroundColor: state.isSelected ? '#2c2f3300' : state.isFocused ? '#2c2f3300' : null,
      cursor: 'pointer'
    }),
    control: styles => ({
      ...styles,
      width: isLanguageOpen ? '170px' : '90px', 
      backgroundColor: '#2c2f3300',
      color: 'white',
      border:'none',
      cursor: 'pointer',
      transition: 'all .4s ease-in-out'

    }),
    input: (styles) => ({
        ...styles,
        color: 'white',

    }),
    container: (base) => ({
        ...base,
        color: 'white',
        width: '100%' // Select bileşeninin genişliği
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'white', // Seçilen değerin yazı rengi
      backgroundColor: '#292d3400',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: 'white', // Placeholder yazı rengi
      backgroundColor: '#292d34',
    }),
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: '#292d34', // Açılan menünün arka plan rengi
        border: 'none',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)',
        width: '100%'
    }),
    menuList: (provided, state) => ({
    ...provided,
    backgroundColor: '#292d34',
    padding: 0,
    border: 'none'
    }),
    
  };
  const options = [
    { value: 'ar', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Arabic'} /></span>) },
    { value: 'bg', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Bulgarian'} /></span>) },
    { value: 'en', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'English'} /></span>) },
    { value: 'zh', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Chinese'} /></span>) },
    { value: 'cs', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Czech'} /></span>) },
    { value: 'nl', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Dutch'} /></span>) },
    { value: 'fr', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'French'} /></span>) },
    { value: 'de', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'German'} /></span>) },
    { value: 'el', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Greek'} /></span>) },
    { value: 'hu', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Hungarian'} /></span>) },
    { value: 'id', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Indonesian'} /></span>) },
    { value: 'it', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Italian'} /></span>) },
    { value: 'ja', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Japanese'} /></span>) },
    { value: 'ko', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Korean'} /></span>) },
    { value: 'pl', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Polish'} /></span>) },
    { value: 'pt', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Portuguese'} /></span>) },
    { value: 'ro', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Romanian'} /></span>) },
    { value: 'ru', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Russian'}/></span> )},
    { value: 'sk', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Slovak'} /></span>) },
    { value: 'es', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Spanish'} /></span>) },
    { value: 'th', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Thai'}/></span>) },
    { value: 'tr', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Turkish'}/></span>) },
    { value: 'vi', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Vietnamese'}/></span>) },
  ];


  return (
      <div className='Navbar'>
        {showLogin && <Login />}
       
        <div className='Center'>
          <div className="Sol">
            <img src={process.env.PUBLIC_URL + '/Icon.png'} alt="Menu" className="LogoBoyut"></img>
            <img src={process.env.PUBLIC_URL + '/Logo.png'} alt="Menu" className="LogoAnim"></img>
          </div>
          <div className='Sag'>
            <ul className="Menu">
              <li><Link to="H-Ozellik1" spy={true} smooth={true} offset={0} duration={500}>{t('Streams')}</Link></li>
              <li><Link to="H-Ozellik2" spy={true} smooth={true} offset={0} duration={500}>{t('Chat')}</Link></li>
              <li><Link to="H-Ozellik3" spy={true} smooth={true} offset={0} duration={500}>{t('Market')}</Link></li>
              <li><Link to="H-Ozellik4" spy={true} smooth={true} offset={0} duration={500}>{t('Games')}</Link></li>
              <div className={isLanguageOpen ?'Home-Panel-Sag-Menu-language-Open':'Home-Panel-Sag-Menu-language'}>
                  <Select
                      value={options.find(option => option.value === Language)}
                      onChange={handleChange}
                      options={options}
                      styles={customStyles}
                      isSearchable={false}
                      onMenuOpen={() => setIsLanguageOpen(true)}
                      onMenuClose={() => setIsLanguageOpen(false)} 
                  />
                </div>
              <div className={LoginStatus ? 'Login-Register-Close' : 'Login-Register'}>
               <div className="Register" onClick={() => {setShowLogin(true); setRegister(true); setLogin(false);}} >{t('Sign up')}</div>  
               <div className="Login" onClick={() => {setShowLogin(true);setRegister(false); setLogin(true);}} >{t('Sign in')}</div>  
              </div>
              
              <RouterLink to="./Dashboard" className={LoginStatus ? 'Login-Dashboard' : 'Login-Dashboard-Close'}>
                <div className="Login-Dashboard-Button">{t('Dashboard')}</div>  
              </RouterLink>
             
            </ul>
            <div className='MenuMobile' onClick={()=> setMenu(!Menu)}>
              <img src="Menu.png" alt="Menu"></img>
            </div>
          </div>
        </div>
       
      </div>
  )
}

export default Navbar