import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import { waveform } from 'ldrs'
import Cookies from 'js-cookie';
import { FaRegCopy ,FaQrcode } from "react-icons/fa";
import './Deposit_.css';
import CoinIcon from '../CoinIcon/CoinIcon';
import DepositRecent from './DepositRecent';
import { Degiskenler } from '../../Context/GlobalDegiskenler';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
function Deposit() {
    const { t } = useTranslation();
    const location = useLocation();
    const [Loader, setLoader] = useState(false);
    const {refreshToken,BackHome, SelectedCoin, setSelectedCoin} = useContext(Degiskenler);
    const [isInputFocused, setInputFocused] = useState(false);

    const [total, setTotal] = useState(0);//////////
    const [available, setAvailable] = useState(0);////////
    const [MinimumDeposit, setMinimumDeposit] = useState("0");/////
    const [network, setNetwork] = useState('');//////////////
    const [DepositAddress, setDepositAddress] = useState({});//////////
    const [history, setHistory] = useState([]);
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',
          color: 'white', // Seçeneklerin yazı rengi
          backgroundColor: state.isSelected ? '#292d34' : state.isFocused ? '#292d34' : null,
        }),
        control: styles => ({
          ...styles,
          width: '100%',
          backgroundColor: '#292d34',
          color: 'white'
        }),
        input: (styles) => ({
            ...styles,
            color: 'white',
        }),
        container: (base) => ({
            ...base,
            color: 'white',
            width: '100%' // Select bileşeninin genişliği
          }),
        singleValue: (provided, state) => ({
          ...provided,
          color: 'white', // Seçilen değerin yazı rengi
          backgroundColor: '#292d34',
          opacity: isInputFocused ? 0.5 : 1,
          pointerEvents: 'none',
        }),
        placeholder: (provided, state) => ({
          ...provided,
          color: 'white', // Placeholder yazı rengi
          backgroundColor: '#292d34',
          pointerEvents: 'none',
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#292d34', // Açılan menünün arka plan rengi
            border: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)',
            width: '100%'
        }),
        menuList: (provided, state) => ({
        ...provided,
        backgroundColor: '#292d34',
        padding: 0,
        border: 'none'
        })
    };
    const options = [
    { value: 'BTC', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'BTC'} CoinIcon_={true} CoinName_= {true} /> {'(BTC)'}</span>) },
    { value: 'ETH', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'ETH'} CoinIcon_={true} CoinName_= {true} /> {'(ETH)'}</span>) },
    { value: 'USDT', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'USDT'} CoinIcon_={true} CoinName_= {true} /> {'(USDT)'}</span>) },
    { value: 'USDC', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'USDC'} CoinIcon_={true} CoinName_= {true} /> {'(USDC)'}</span>) },
    { value: 'BNB', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'BNB'} CoinIcon_={true} CoinName_= {true} /> {'(BNB)'}</span>) },
    { value: 'XRP', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'XRP'} CoinIcon_={true} CoinName_= {true} /> {'(XRP)'}</span>) },
    { value: 'DOGE', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'DOGE'} CoinIcon_={true} CoinName_= {true} /> {'(DOGE)'}</span>) },
    { value: 'ADA', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'ADA'} CoinIcon_={true} CoinName_= {true} /> {'(ADA)'}</span>) },
    { value: 'TRX', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'TRX'} CoinIcon_={true} CoinName_= {true} /> {'(TRX)'}</span>) },
    { value: 'SHIB', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'SHIB'} CoinIcon_={true} CoinName_= {true} /> {'(SHIB)'}</span>) },
    { value: 'DOT', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'DOT'} CoinIcon_={true} CoinName_= {true} /> {'(DOT)'}</span>) },
    { value: 'BCH', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'BCH'} CoinIcon_={true} CoinName_= {true} /> {'(BCH)'}</span>) },
    { value: 'LINK', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'LINK'} CoinIcon_={true} CoinName_= {true} /> {'(LINK)'}</span>) },
    { value: 'LTC', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'LTC'} CoinIcon_={true} CoinName_= {true} /> {'(LTC)'}</span>) },
    { value: 'NEAR', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'NEAR'} CoinIcon_={true} CoinName_= {true} /> {'(NEAR)'}</span>) },
    { value: 'MATIC', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'MATIC'} CoinIcon_={true} CoinName_= {true} /> {'(MATIC)'}</span> )},
    { value: 'UNI', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'UNI'} CoinIcon_={true} CoinName_= {true} /> {'(UNI)'}</span>) },
    { value: 'Pepe', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'Pepe'} CoinIcon_={true} CoinName_= {true} /> {'(Pepe)'}</span>) },
    { value: 'ARB', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'ARB'} CoinIcon_={true} CoinName_= {true} /> {'(ARB)'}</span>) },
    { value: 'FLOKI', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'FLOKI'} CoinIcon_={true} CoinName_= {true} /> {'(FLOKI)'}</span>) },
    { value: 'XLM', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'XLM'} CoinIcon_={true} CoinName_= {true} /> {'(XLM)'}</span>) },
    { value: 'BTT', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'BTT'} CoinIcon_={true} CoinName_= {true} /> {'(BTT)'}</span>) },
    { value: 'FIL', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'FIL'} CoinIcon_={true} CoinName_= {true} /> {'(FIL)'}</span>) },
    { value: 'ATOM', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'ATOM'} CoinIcon_={true} CoinName_= {true} /> {'(ATOM)'}</span>) },
    { value: 'MKR', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'MKR'} CoinIcon_={true} CoinName_= {true} /> {'(MKR)'}</span>) },
    { value: 'VET', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'VET'} CoinIcon_={true} CoinName_= {true} /> {'(VET)'}</span>) },
    { value: 'FTM', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'FTM'} CoinIcon_={true} CoinName_= {true} /> {'(FTM)'}</span>) },
    { value: 'MANA', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'MANA'} CoinIcon_={true} CoinName_= {true} /> {'(MANA)'}</span>) },
    
    ];
    const handleChange = selectedOption => {
        setSelectedCoin(selectedOption.value);
        setInputFocused(false);

    };
    const handleCopyAddress = async () => {
        try {
            if (network === 'BNBSmartChain') await navigator.clipboard.writeText(DepositAddress.BNBSmartChain);
            else if (network === 'Ethereum') await navigator.clipboard.writeText(DepositAddress.Ethereum);
            else if (network === 'Polygon') await navigator.clipboard.writeText(DepositAddress.Polygon);
            else if (network === 'Arbitrum') await navigator.clipboard.writeText(DepositAddress.Arbitrum);
            else if (network === 'Fantom') await navigator.clipboard.writeText(DepositAddress.Fantom);
           
        } catch (error) {
            console.error('Failed to copy address:', error);
        }
    };

    useEffect(() => {
        const fetchDepositData = async () => {
            setLoader(true);
            let Status= await refreshToken();
            if(Status){
                const token = Cookies.get('accessToken');
                const requestBody = {coin: SelectedCoin};

                try {
                    const response = await fetch('https://game-bit.com/api/Users/CheckDepositBalance', {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(requestBody)
                    });
            
                    if (!response.ok) {
                        BackHome();
                        console.log(`HTTP error! status: ${response.status}`);
                        return;
                    }
            
                    const data = await response.json();
                    setTotal(data.balance);
                    setAvailable(data.balance);
                    setMinimumDeposit(data.minDeposit);
                    setDepositAddress(data.wallets);
                    setHistory(data.history);
                    var Network_="";
                    Object.keys(data.wallets).forEach(key => {
                        if(Network_===""){
                            setNetwork(key);
                            Network_=key;
                        };
                    });
                    
                    console.log('Deposit Data:', data);
                } catch (error) {
                    BackHome();
                    console.log('Error fetching wallet data:', error);
                }
            }
          setLoader(false);
        };
        fetchDepositData();
    }, [location, refreshToken,BackHome,setNetwork,SelectedCoin]);

    waveform.register()
    return (
        <div className='Deposit-Panel'>
         <h2>{t('Deposit')}</h2>
         <div className='Deposit-Content'>
            <div className='Deposit-Left-Panel'>
                <div className='Deposit-Select-Coin'>
                    <div className='Deposit-Select-Coin-label'>{t('Coin')}:</div>
                    <div className="Deposit-input-group">
                        <Select
                            value={options.find(option => option.value === SelectedCoin)}
                            onChange={handleChange}
                            options={options}
                            styles={customStyles}
                            placeholder="Select or search a coin..."
                            isSearchable={true}
                            noOptionsMessage={() => "No matching results"}
                            loadingMessage={() => "Loading..."}
                            onFocus={() => setInputFocused(true)}
                            onBlur={() => setInputFocused(false)}
                            onMenuOpen={() => setInputFocused(true)}
                            onMenuClose={() => setInputFocused(false)}
                        />
                    </div>
                </div>
                <div className="Deposit-coin-balance">
                    <div className="Transaction-balance-item">
                        <label>{t('Total')}:</label>
                        <span>{Loader && <l-waveform size="20" stroke="2.0" speed="1" color="#ccc"></l-waveform>}{!Loader && `${total} ${SelectedCoin}`} </span>
                    </div>
                    <div className="Transaction-balance-item">
                        <label>{t('Inorder')}:</label>
                        <span>{Loader && <l-waveform size="20" stroke="2.0" speed="1" color="#ccc"></l-waveform>}{!Loader && `${0} ${SelectedCoin}`}</span>
                    </div>
                    <div className="Transaction-balance-item">
                        <label>{t('Available')}:</label>
                        <span>{Loader && <l-waveform size="20" stroke="2.0" speed="1" color="#ccc"></l-waveform>}{!Loader && `${available} ${SelectedCoin}`}</span>
                    </div>
                    <div className="Transaction-balance-item">
                        <label>{t('Minimumdeposit')}:</label>
                        <span>&#62; {Loader && <l-waveform size="20" stroke="2.0" speed="1" color="#ccc"></l-waveform>}{!Loader &&  `${MinimumDeposit} ${SelectedCoin}`}</span>
                    </div>
                </div>
                <div className="Deposit-tips-container">
                    <ul>
                        <li>{t('Deposit-Info1')}</li>
                        <li>{t('Deposit-Info2')}</li>
                    </ul>
                </div>
            </div>
            <div className='Deposit-Right-Panel'>
                <div className="Deposit-network-select-container">
                    <label htmlFor="">{t('SelectNetwork')}</label>
                    <div className='Deposit-network-select-network'>
                        {DepositAddress.BNBSmartChain && <div onClick={() => setNetwork('BNBSmartChain')} className={network === 'BNBSmartChain' ? 'Deposit-network-select-item-active' : 'Deposit-network-select-item-inactive'}>BNB Smart Chain <br></br>(DEP20)</div>}
                        {DepositAddress.Ethereum &&<div onClick={() => setNetwork('Ethereum')} className={network === 'Ethereum' ? 'Deposit-network-select-item-active' : 'Deposit-network-select-item-inactive'}>Ethereum <br></br>(ERC20)</div>}
                        {DepositAddress.Polygon &&<div onClick={() => setNetwork('Polygon')} className={network === 'Polygon' ? 'Deposit-network-select-item-active' : 'Deposit-network-select-item-inactive'}>Polygon</div>}
                        {DepositAddress.Arbitrum &&<div onClick={() => setNetwork('Arbitrum')} className={network === 'Arbitrum' ? 'Deposit-network-select-item-active' : 'Deposit-network-select-item-inactive'}>Arbitrum</div>}
                        {DepositAddress.Fantom &&<div onClick={() => setNetwork('Fantom')} className={network === 'Fantom' ? 'Deposit-network-select-item-active' : 'Deposit-network-select-item-inactive'}>Fantom</div>}
                    </div>
                    
                </div>
                <div className='Deposit-Enter-Content'>
                    <div className='Deposit-Address-Panel'>
                        <label className='Deposit-Address-name'>{SelectedCoin} {t('Address')}</label>
                        {network ==='BNBSmartChain' && <label className='Deposit-Address'>{DepositAddress.BNBSmartChain}</label>}
                        {network ==='Ethereum' && <label className='Deposit-Address'>{DepositAddress.Ethereum}</label>}
                        {network ==='Polygon' && <label className='Deposit-Address'>{DepositAddress.Polygon}</label>}
                        {network ==='Arbitrum' && <label className='Deposit-Address'>{DepositAddress.Arbitrum}</label>}
                        {network ==='Fantom' && <label className='Deposit-Address'>{DepositAddress.Fantom}</label>}
                    </div>
                    <div className="Deposit-address-buttons">
                        <div className='Deposit-address-button' onClick={() => handleCopyAddress()}>
                            <FaRegCopy />
                            <label htmlFor="">{t('Copyaddress')}</label>
                        </div>
                        
                    </div>
                </div>
                <div className="Deposit-info">
                    <label>{t('Sendonly')} {SelectedCoin} {t('tothisdepositaddress')}</label>
                    <label>{t('Deposit-Info3')} {SelectedCoin} {t('Deposit-Info4')}</label>
                    
                       
                </div>
            </div>
         </div>
         <DepositRecent History={history} />
        </div>
    );
}

export default Deposit;

/*
<div className='Deposit-address-button'>
                            <FaQrcode />
                            <label htmlFor="">{t('ShowQRcode')}</label>
                        </div>
*/
