import './Menu-Dashboard_.css';
import React, {useContext,useState} from 'react';
import {Link } from 'react-router-dom';
import { debounce } from 'lodash';
import { Degiskenler } from '../../Context/GlobalDegiskenler';
import { useTranslation } from 'react-i18next';

import LanguageIcon from '../../components/Language/Language';
import Select from 'react-select';
function Login({ setPanel }) {
    const {i18n, t } = useTranslation();
    const {Menu, setMenu, setLanguage,Language} = useContext(Degiskenler);
    const [isLanguageOpen, setIsLanguageOpen] = useState(false);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };
    const handleButtonClick = debounce((buttonName) => {
      setPanel(buttonName);
    }, 350);

    const handleChange = selectedOption => {
        setLanguage(selectedOption.value);
        changeLanguage(selectedOption.value);
        localStorage.setItem('Language',selectedOption.value);
      };
      const customStylesMobile = {
        option: (provided, state) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',
          color: 'white', // Seçeneklerin yazı rengi
          backgroundColor: state.isSelected ? '#2c2f3300' : state.isFocused ? '#2c2f3300' : null,
          cursor: 'pointer',
          userSelect: 'none'
        }),
        control: styles => ({
          ...styles,
          width: '100%', 
          backgroundColor: '#2c2f3300',
          color: 'white',
          border:'none',
          cursor: 'pointer',
          transition: 'all .4s ease-in-out',
          userSelect: 'none'
        }),
        input: (styles) => ({
            ...styles,
            color: 'white',
          userSelect: 'none'
        }),
        container: (base) => ({
            ...base,
            color: 'white',
            width: '100%',
            userSelect: 'none'
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: 'white', // Seçilen değerin yazı rengi
          backgroundColor: '#292d3400',
            userSelect: 'none',
            pointerEvents: 'none',
        }),
        placeholder: (provided, state) => ({
          ...provided,
          color: 'white', // Placeholder yazı rengi
          backgroundColor: '#292d34',
          userSelect: 'none'
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#292d34', // Açılan menünün arka plan rengi
            border: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)',
            width: '100%',
            userSelect: 'none'
        }),
        menuList: (provided, state) => ({
        ...provided,
        backgroundColor: '#292d34',
        padding: 0,
        marginTop:'-5px',
        border: 'none',
        userSelect: 'none'
        }),
        indicatorSeparator: () => ({
              
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            display:'none'
        }),
      };
      const options = [
        { value: 'ar', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Arabic'} /></span>) },
        { value: 'bg', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Bulgarian'} /></span>) },
        { value: 'en', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'English'} /></span>) },
        { value: 'zh', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Chinese'} /></span>) },
        { value: 'cs', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Czech'} /></span>) },
        { value: 'nl', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Dutch'} /></span>) },
        { value: 'fr', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'French'} /></span>) },
        { value: 'de', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'German'} /></span>) },
        { value: 'el', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Greek'} /></span>) },
        { value: 'hu', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Hungarian'} /></span>) },
        { value: 'id', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Indonesian'} /></span>) },
        { value: 'it', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Italian'} /></span>) },
        { value: 'ja', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Japanese'} /></span>) },
        { value: 'ko', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Korean'} /></span>) },
        { value: 'pl', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Polish'} /></span>) },
        { value: 'pt', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Portuguese'} /></span>) },
        { value: 'ro', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Romanian'} /></span>) },
        { value: 'ru', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Russian'}/></span> )},
        { value: 'sk', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Slovak'} /></span>) },
        { value: 'es', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Spanish'} /></span>) },
        { value: 'th', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Thai'}/></span>) },
        { value: 'tr', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Turkish'}/></span>) },
        { value: 'vi', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Vietnamese'}/></span>) },
      ];
    return (
        <div className={Menu ? 'Dashboard-MenuPanel':'Dashboard-MenuPanelPasif' }>
        <div className="Dashboard-UstMenu">
          <Link className="Dashboard-logoMenu" to={"./Home"}>
            <img src="Icon.png" alt="Close" className="Dashboard-MenuIconBackround"></img>
            <img src="Logo.png" alt="Close" className="Dashboard-MenuIcon" ></img>
          </Link>
          <div className="Dashboard-MenuPanelKapat" onClick={()=> setMenu(!Menu)}>
            <img src="Close.png" alt="Close"></img>
          </div> 
          <div className='Dashboard-MenuCizgi'></div>
        </div>
        
        <div className="Dashboard-AltMenu">
          <div className="Dashboard-Panel-MenuList"  onClick={()=>{setMenu(!Menu); handleButtonClick('Wallet');}} >
              <div className='Dashboard-Panel-MenuActiveBar'></div>
              <div className='Dashboard-Panel-MenuText'>{t('Wallet')}</div>
            </div>
            <div className="Dashboard-Panel-MenuList"  onClick={()=> {setMenu(!Menu); handleButtonClick('BuySel');}}>
              <div className='Dashboard-Panel-MenuActiveBar'></div>
              <div className='Dashboard-Panel-MenuText'>{t('BuySell')}</div>
            </div>
            <div className="Dashboard-Panel-MenuList"  onClick={()=> {setMenu(!Menu); handleButtonClick('Deposit');}}>
              <div className='Dashboard-Panel-MenuActiveBar'></div>
              <div className='Dashboard-Panel-MenuText' >{t('Deposit')}</div>
            </div>
            <div className="Dashboard-Panel-MenuList"  onClick={()=> {setMenu(!Menu); handleButtonClick('Withdraw');}}>
              <div className='Dashboard-Panel-MenuActiveBar' ></div>
              <div className='Dashboard-Panel-MenuText'>{t('Withdraw')}</div>
            </div>
            <div className="Dashboard-Panel-MenuList"  onClick={()=> {setMenu(!Menu); handleButtonClick('Referance');}}>
              <div className='Dashboard-Panel-MenuActiveBar' ></div>
              <div className='Dashboard-Panel-MenuText'>{t('Referance')}</div>
            </div>
          <div className={ isLanguageOpen ? 'Dashboard-Panel-Sag-Menu-language-Mobile-Open':'Dashboard-Panel-Sag-Menu-language-Mobile-Close'}>
            <Select
              value={options.find(option => option.value === Language)}
              onChange={handleChange}
              options={options}
              styles={customStylesMobile}
              onMenuOpen={() => setIsLanguageOpen(true)}
              onMenuClose={() => setIsLanguageOpen(false)} 
              isSearchable={false}
            />
          </div>

          <div className='Dashboard-MenuButtons'>
            <Link to="/" className='Dashboard-MenuLogin' onClick={() => {setMenu(!Menu);}}>{t('Home')}</Link>
          </div>
        </div>
    </div>
    );
  }
  
export default Login;
