import {useEffect, createContext , useState, useCallback} from "react";
import Cookies from 'js-cookie';
import { useNavigate   } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
export const Degiskenler = createContext();
export const GlobalSaglayici =(props) =>{
    const { i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [Language,setLanguage] = useState('en');
    const [Menu,setMenu] = useState(false);
    const [AcilirVideo,AcilirVideoDurum] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [LoginStatus, setLoginStatus] = useState(false);
    const [Login, setLogin] = useState(true);
    const [Register, setRegister] = useState(false);
    const [userCode, setuserCode] = useState('');
    const [SelectedCoin, setSelectedCoin] = useState('USDT');
    const [SelectedCrypto, setSelectedCrypto] = useState('BTC');
    const [Wallet, setWallet] = useState([
        { coin: 'GBIT', Name: 'GameBit', Balance: '0', Price: '0', Deposit: false , Withdraw: false , Trade: true},
        { coin: 'BTC', Name: 'Bitcoin', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'ETH', Name: 'Ethereum', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'USDT', Name: 'TetherUS', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'USDC', Name: 'USD Coin', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'BNB', Name: 'BNB', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'XRP', Name: 'Ripple', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'DOGE', Name: 'Dogecoin', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'ADA', Name: 'Cardano', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'TRX', Name: 'TRON', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'SHIB', Name: 'SHIBA INU', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'DOT', Name: 'Polkadot', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'BCH', Name: 'Bitcoin Cash', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'LINK', Name: 'ChainLink', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'LTC', Name: 'Litecoin', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'NEAR', Name: 'NEAR Protocol', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'MATIC', Name: 'Polygon', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'UNI', Name: 'Uniswap', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'PEPE', Name: 'Pepe', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'ARB', Name: 'Arbitrum', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'FLOKI', Name: 'FLOKI', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'XLM', Name: 'Stellar', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'BTT', Name: 'Bittorrent', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'FIL', Name: 'Filecoin', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'ATOM', Name: 'Cosmos', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'MKR', Name: 'Maker', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'VET', Name: 'VeChain', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'FTM', Name: 'Fantom', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
        { coin: 'MANA', Name: 'Decentraland', Balance: '0', Price: '0', Deposit: true , Withdraw: false , Trade: true},
    ]);
    const [cryptoData, setCryptoData] = useState([
        { pair: 'GBIT', price: '0.0005', change: '0,00', changePercent: '0,00'},
        { pair: 'BTC', price: '0,00', change: '0,00', changePercent: '0,00' },
        { pair: 'ETH', price: '0,00', change: '0,00', changePercent: '0,00' },
        { pair: 'BNB', price: '0,00', change: '0,00' , changePercent: '0,00'},
        { pair: 'XRP', price: '0,00', change: '0,00' , changePercent: '0,00'},
        { pair: 'DOGE', price: '0,00', change: '0,00', changePercent: '0,00' },
        { pair: 'ADA', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'TRX', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'SHIB', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'DOT', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'BCH', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'LINK', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'LTC', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'NEAR', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'MATIC', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'UNI', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'PEPE', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'ARB', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'FLOKI', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'XLM', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'BTT', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'FIL', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'ATOM', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'MKR', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'VET', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'FTM', price: '0,00', change: '0,00', changePercent: '0,00'},
        { pair: 'MANA', price: '0,00', change: '0,00', changePercent: '0,00'},
    ]);
    const [PNL, setPNL] = useState({ today: "0,00", pnl: "0,00"});
    
    const refreshToken = useCallback(async () => {  
        try {
            const accessToken = Cookies.get('accessToken');
            if (accessToken) {
                console.log("Accsestoken var ");
                setLoginStatus(true);
                return true;
            }
            else{
                console.log("Accsestoken yok");
                const refreshTokenValue = Cookies.get('refreshToken');
                if (!refreshTokenValue) {
                    console.log('No refresh token available.');
                    setLoginStatus(false);
                    if (window.location.pathname === '/Dashboard') {
                        navigate('/'); // Sadece Dashboard'dan Anasayfaya yönlendir
                    }
                    return false;
                }
                const response = await fetch('https://game-bit.com/api/Users/refreshToken', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': refreshTokenValue
                    },
                });
                if (!response.ok) {
                    console.log("Failed to refresh token");
                    Cookies.remove('refreshToken');
                    setLoginStatus(false);
                    if (window.location.pathname === '/Dashboard') {
                        navigate('/'); // Sadece Dashboard'dan Anasayfaya yönlendir
                    }
                    return false;
                }
                const responseData = await response.json();
                Cookies.set('accessToken', responseData.token, { expires: 2 / 24, secure: true, sameSite: 'strict' });
                setLoginStatus(true);
                console.log('Access token refreshed successfully', responseData.token);
                return true;
            }
           
        } catch (error) {
            console.error('Error refreshing access token:', error);
            if (window.location.pathname === '/Dashboard') {
                navigate('/'); // Sadece Dashboard'dan Anasayfaya yönlendir
            }
            return false;
        }
    }, [setLoginStatus, navigate]);
    
    const BackHome = useCallback(async () => { 
        Cookies.remove('refreshToken'); 
        Cookies.remove('accessToken');

        setLoginStatus(false);
        if (window.location.pathname === '/Dashboard') {
            navigate('/'); // Sadece Dashboard'dan Anasayfaya yönlendir
        }
        return false;
    }, [setLoginStatus, navigate]);
    useEffect(() => {
        const savedLanguage = localStorage.getItem('Language');
        
        if (savedLanguage) {
          setLanguage(savedLanguage);
        } else {
          setLanguage(navigator.language.split('-')[0]);
        }
        i18n.changeLanguage(Language);
      }, [location,i18n, Language, setLanguage]);
/*
    useEffect(() => {
        //Cookies.remove('refreshToken');
        //Cookies.remove('accessToken');
        
        const accessToken = Cookies.get('accessToken');
        if (accessToken) {
            console.log("Accsestoken var ");
            setLoginStatus(true);
        }
        else{
            console.log("Accsestoken yok");
            refreshToken();
        }
       
    }, [location, setLoginStatus, refreshToken]);*/


    useEffect(() => {
        //Cookies.remove('refreshToken');
        //Cookies.remove('accessToken');
        
        
    }, []);
    

    return <Degiskenler.Provider value={{
        Menu,
        setMenu,

        
        refreshToken, 
        BackHome,

        Language,
        setLanguage,

        AcilirVideo,
        AcilirVideoDurum,

        showLogin,
        setShowLogin,

        LoginStatus,
        setLoginStatus,

        Login,
        setLogin,

        Register,
        setRegister,

        userCode,
        setuserCode,

        SelectedCoin,
        setSelectedCoin,

        Wallet,
        setWallet,

        cryptoData,
        setCryptoData,

        PNL,
        setPNL,

        SelectedCrypto,
        setSelectedCrypto
    }}>
        {props.children}
    </Degiskenler.Provider>
}