import './Home_.css';
import { Link } from "react-router-dom";
import React, { useEffect, useRef ,useState,useContext } from 'react';

import Typed from 'typed.js';
import { Degiskenler } from '../../Context/GlobalDegiskenler';
import Navbar from '../../components/navbar/Navbar'
import Menu from '../../components/Menu/Menu'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaWindowClose } from "react-icons/fa";


const Home = () => {
  const {t} = useTranslation();
  const {Language,refreshToken,LoginStatus, setShowLogin,setLogin,setRegister} = useContext(Degiskenler);


  const [HomeInfo, setHomeInfo] = useState(false);

  const location = useLocation();

  const el = useRef(null);
  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [t('Game Library'),t('Friendship System'),t('Group Video Chat'),t('Live Broadcast'),t('Cryptomarket')],
      loop:true,
      typeSpeed: 100,
      backSpeed: 50 
    });
    return () => {
      typed.destroy();
    };
  }, [t,Language]);

  const [SeciliOyun, SeciliOyunDegis] = useState(0);
  const [VideoLinki, VideoLinkiDegis] = useState('https://game-bit.com/FPS.mp4');
  const [VideosBalik, VideoBaslikDegis] = useState('FPS');
  const [VideosAsama, VideoAsamaDegis] = useState(t('symbolic'));
  const [VideoAciklama, VideoAciklamaDegis] = useState(t('VideoInfo3'));

  const [SeciliAlan, SeciliAlanDegis] = useState(0);

  const YaziAc=(gelis)=>{
    if(gelis === SeciliAlan)
    {
      SeciliAlanDegis(0);
    }
    else{
      SeciliAlanDegis(gelis)
    }
  }


  useEffect(() => {
    refreshToken();
   
}, [location, refreshToken]);

useEffect(() => {
  VideoAsamaDegis(t('symbolic'));
  VideoAciklamaDegis(t('VideoInfo3'));
}, [t,Language]);
useEffect(() => {
  const infoLog = localStorage.getItem('infoLog');

  if (!infoLog) {
    const timer = setTimeout(() => {
      setHomeInfo(true);
      localStorage.setItem('infoLog', 'true');
    }, 100);
    return () => clearTimeout(timer);
  }
}, []);
useEffect(() => {
    document.body.style.overflow = HomeInfo ? 'hidden' : 'auto';
 
}, [HomeInfo]);



  return (
    
    <div className='Home'>
      <Navbar/>
      <Menu/>
      <div className='HomeBackEffect'></div>
      <div className='HomeUst'>
        <div className='HomeUstSol'>
          <h3>{t('Features i have')} <br></br> <span ref={el} id='Text'></span></h3>
          <h1>{t('Our mini exchange')}</h1>
          <div className='HomeInfo-Login'>
            <div className={LoginStatus ? 'Login-Register-Close' : 'HomeInfo-Login'}>
              <div className='HomeUstSolButton' onClick={() => {setShowLogin(true);setRegister(false); setLogin(true);}}>{t('Sign in')}</div>
              <label htmlFor=""> {t('Or')} </label>
              <label htmlFor="" onClick={() => {setShowLogin(true); setRegister(true); setLogin(false);}}> {t('Sign up')} </label>
            </div>
            <Link to="./Dashboard" className={LoginStatus ? 'Login-Dashboard' : 'Login-Dashboard-Close'}>
              <div className="Login-Dashboard-Button">{t('Dashboard')}</div>  
            </Link>
          </div>
          
        </div>
        <div className='HomeUstSag'>
          <img src="Images/Resim3.svg" alt="Resim3" />
          <img src="Images/Resim0.png" alt="Resim0" />
          <img src="Images/Resim1.png" alt="Resim1" />
          <img src="Images/Resim0.png" alt="Resim0" />
          <img src="Images/Resim1.png" alt="Resim1" />
          <img src="Images/Resim1.png" alt="Resim1" />
          <img src="Images/Resim0.png" alt="Resim1" />
          <img src="Images/Resim1.png" alt="Resim1" />
          <img src="EkranGorsel.png" alt="EkranGorsel" />
        </div>
      </div>


      <div className='HomeOyunTanitim'>
        <div className='HomeOyunOrta'>       
          <h3>{t('Trade Cryptocurrencies')}</h3>
          <h1>{t('Use your wallet')}</h1>
          <div className='HomePhotoAlani'>
            <div className="HomePhoto">
              <img src="Images/WalletScreen.png" alt="WalletScreen" />
            </div>
          </div>
          
        </div>
      </div>
      <div className='H-Ozellik1'>
        <div className='H-Ozellik-Alani'>
          <div className='H-Ozellik-Aciklama'>
            <div className='H-Ozellik-KucukBaslik'>
              <img src="stream.png" alt="Resim"></img>
              <h1>{t('PGAME STREAMING HUB')}</h1>
            </div>
            <div className='H-Ozellik1-Baslik'>{t('Earn Crypto')}</div>
            <div className='H-Ozellik-Baslik2'>{t('Stream Earn')}</div>
            <p className='H-Ozellik-Baslik3'>{t('Our live streaming')}</p>
            <div className='H-Ozellik-Baslik2'>{t('Expand Your Reach')}</div>
            <p className='H-Ozellik-Baslik3'>{t('Our platform extends')}</p>
          </div>
          <div className='H-Ozellik-Video'>
            <div className='H-OzellikVideo'>
              <img src="Images/LivePanel.png" alt="LivePanel" />
            </div>
          </div>
        </div>
      </div>
      <div className='H-Ozellik2'>
        <div className='H-Ozellik-Alani'>
          <div className='H-Ozellik2-Video'>
            <div className='H-OzellikVideo'>
              <img src="Images/chat.png" alt="chat" />
            </div>
          </div>
          <div className='H-Ozellik2-Aciklama'>
            <div className='H-Ozellik-KucukBaslik'>
              <img src="chat.png" alt="Resim"></img>
              <h1>{t('PGAME CHAT PLATFORM')}</h1>
            </div>
            <div className='H-Ozellik2-Baslik'>{t('Enhance Your Digital')}</div>
            <div className='H-Ozellik-Baslik2'>{t('Communicate Securely')}</div>
            <p className='H-Ozellik-Baslik3'>{t('Our platform introduces')}</p>
            <div className='H-Ozellik-Baslik2'>{t('GBIT Currency Transfer')}</div>
            <p className='H-Ozellik-Baslik3'>{t('The app also includes')}</p>
          </div>
        </div>
      </div>
      <div className='H-Ozellik3'>
        <div className='H-Ozellik-Alani'>
          <div className='H-Ozellik-Aciklama'>
            <div className='H-Ozellik-KucukBaslik'>
              <img src="Game Controller.png" alt="Resim"></img>
              <h1>{t('PGAME GAME MARKET')}</h1>
            </div>
            <div className='H-Ozellik3-Baslik'>{t('Enhance Gaming')}</div>
            <div className='H-Ozellik-Baslik2'>{t('Monetize Your')}</div>
            <p className='H-Ozellik-Baslik3'>{t('Our platform includes')}</p>
            <div className='H-Ozellik-Baslik2'>{t('Launching Diverse')}</div>
            <p className='H-Ozellik-Baslik3'>{t('Were also')}</p>
          </div>
          <div className='H-Ozellik-Video'>
            <div className='H-OzellikVideo'>
            <img src="Images/Store.png" alt="LivePanel" />
            </div>
          </div>
        </div>
      </div>
      <div className='H-Ozellik4'>
        <div className='H-Ozellik-Alani'>
          <div className='H-Resim'>
            <img src="o.png" alt="Resim"></img>
          </div>
          <div className='H-Ozellik2-Aciklama'>
            <div className='H-Ozellik-KucukBaslik'>
              <img src="Game Controller.png" alt="Resim"></img>
              <h1>{t('GBIT LIBRARY')}</h1>
            </div>
            <div className='H-Ozellik4-Baslik'>{t('Expand Gaming')}</div>
            <div className='H-Ozellik-Baslik2'>{t('Your Gateway')}</div>
            <p className='H-Ozellik-Baslik3'>{t('Our platform')}</p>
            <p className='H-Ozellik-Baslik3'>{t('This integration')}</p>
            <div className='H-Ozellik-Baslik2'>{t('Boosting Game')}</div>
            <p className='H-Ozellik-Baslik3'>{t('Trade within')}</p>
          </div>  
        </div>

      </div>
      <div className='H-Desteklenen'>
        <div className='H-DesteklenenOrta'>
          <h3>{t('Learn About')}</h3>
          <h1>{t('Get ready')}</h1>
         
          <div className='H-DesteklenenVideo'>
            <video autoPlay muted loop key={VideoLinki}>
              <source src={VideoLinki}type="video/mp4" />
            </video>
            <div className='H-DesteklenenVideoPerde'>
              <div className='H-PerdeSol'>
                <div className='H-PerdeSolResimBackground'
                  onClick={() => {SeciliOyunDegis(0);
                  VideoLinkiDegis('https://game-bit.com/FPS.mp4');
                  VideoBaslikDegis('FPS');
                  VideoAsamaDegis(t('symbolic'));
                  VideoAciklamaDegis(t('VideoInfo3'));
                 }}>
                  <div className={`H-PerdeSolResim ${SeciliOyun === 0 ? 'selected' : ''}`}>
                    <img src="FPS.jpg" alt="FPS" />
                  </div>
                </div>
                <div className='H-PerdeSolResimBackground' 
                  onClick={() => {SeciliOyunDegis(1); 
                  VideoLinkiDegis('https://game-bit.com/MMORPG.mp4')
                  VideoBaslikDegis('MMORPG');
                  VideoAsamaDegis(t('symbolic'));
                  VideoAciklamaDegis(t('VideoInfo2'));
                  console.log(SeciliOyun);
                }}>
                  <div className={`H-PerdeSolResim ${SeciliOyun === 1 ? 'selected' : ''}`}>
                  <img src="MMO.jpg" alt="MMO" />
                  </div>
                </div>
                <div className='H-PerdeSolResimBackground'
                  onClick={() => {SeciliOyunDegis(2);
                  VideoLinkiDegis('https://game-bit.com/BattleRoyale.mp4')
                  VideoBaslikDegis('Battle Royale');
                  VideoAsamaDegis(t('symbolic'));
                  VideoAciklamaDegis(t('VideoInfo1'));
                }}>
                  <div className={`H-PerdeSolResim ${SeciliOyun === 2 ? 'selected' : ''}`}>
                    <img src="BattleRoyale.jpg" alt="BattleRoyale" />
                  </div>
                </div>
              </div>
            </div>
            <div className='H-VideoAciklama'>
              <ul>
                <li> <span>GBIT</span> {VideosBalik}</li>
                <li> ({VideosAsama})</li>
                <li> {VideoAciklama}</li>
              </ul>
            </div>
          </div>
          <div className='H-DesteklenenMobile'>
            <div className='H-DesteklenenMobileItem'
              onClick={() => {SeciliOyunDegis(0);
                VideoLinkiDegis('https://game-bit.com/FPS.mp4');
                VideoBaslikDegis('FPS');
                VideoAsamaDegis('Under Development');
                VideoAciklamaDegis(t('VideoInfo3'));
              }}
            >
              <div className={`H-DesteklenenMobileResim ${SeciliOyun === 0 ? 'selected' : ''}`}>
                <img src="fpshor.png" alt="FPS" />
              </div>              
            </div>
            <div className='H-DesteklenenMobileItem'
              onClick={() => {SeciliOyunDegis(1); 
                VideoLinkiDegis('https://game-bit.com/MMORPG.mp4')
                VideoBaslikDegis('MMORPG');
                VideoAsamaDegis('Coming Soon');
                VideoAciklamaDegis(t('VideoInfo2'));
              }}
            >
              <div className={`H-DesteklenenMobileResim ${SeciliOyun === 1 ? 'selected' : ''}`}>
                <img src="mmohor.png" alt="mmo" />
              </div>         
            </div>
            <div className='H-DesteklenenMobileItem'
              onClick={() => {SeciliOyunDegis(2);
              VideoLinkiDegis('https://game-bit.com/BattleRoyale.mp4')
              VideoBaslikDegis('Battle Royale');
              VideoAsamaDegis('In Progress');
              VideoAciklamaDegis(t('VideoInfo1'));
            }}>
              <div className={`H-DesteklenenMobileResim ${SeciliOyun === 2 ? 'selected' : ''}`}>
                <img src="battleroyalehor.png" alt="FPS" />
              </div>         
            </div>
          </div>
          <div className='H-DesteklenenMobileAciklama'>
            <li> <span>GBIT</span> {VideosBalik}</li>
            <li> ({VideosAsama})</li>
            <li> {VideoAciklama}</li>
          </div>
        </div>
      
      </div>
      <div className='H-Fiyat'>
        <div className='H-FiyatBaslik'>
          <h1>{t('YOUR GBIT JOURNEY')}</h1>
          <h3>{t('Embark on the')}</h3>
        </div>
        <div className='H-FiyatAlani'>
          <div className='H-FiyatAlaniResim'>
            <img src="BattleRoyaleKapak.png" alt="BattleRoyaleKapak" />
            
          </div>
          <div className='H-FiyatAlaniAciklama'>
            <div className='H-FiyatAlaniAciklamaDiv'>
              <div className='H-FiyatAlaniAciklamaDivButton' onClick={()=> {YaziAc(1)}}>
                <h3>{t('What Are the')}</h3><span>{SeciliAlan === 1 ? '∧' : 'V' }</span>
              </div>
              {SeciliAlan === 1 && (
              <div className='H-FiyatAlaniAciklamaDivAciklama'>
                {t('Like any')}
              </div>
               )}
            </div>
            <div className='H-FiyatAlaniAciklamaDiv'>
              <div className='H-FiyatAlaniAciklamaDivButton' onClick={()=> {YaziAc(2)}}>
                <h3>{t('Which Exchanges')}</h3><span>{SeciliAlan === 2 ? '∧' : 'V' }</span>
              </div>
              {SeciliAlan === 2 && (
                <div className='H-FiyatAlaniAciklamaDivAciklama'>
                  {t('GBIT will')}
                </div>
               )}
            </div>
            <div className='H-FiyatAlaniAciklamaDiv'>
              <div className='H-FiyatAlaniAciklamaDivButton' onClick={()=> {YaziAc(3)}}>
                <h3>{t('What Are GBIT')}</h3><span>{SeciliAlan === 3 ? '∧' : 'V' }</span>
              </div>
              {SeciliAlan === 3 && (
                <div className='H-FiyatAlaniAciklamaDivAciklama'>
                  {t('GBIT uses')}
                </div>
              )}
            </div>
            <div className='H-FiyatAlaniAciklamaDiv'>
              <div className='H-FiyatAlaniAciklamaDivButton' onClick={()=> {YaziAc(4)}}>
                <h3>{t('What Are the Plans')}</h3><span>{SeciliAlan === 4 ? '∧' : 'V' }</span>
              </div>
              {SeciliAlan === 4 && (
                <div className='H-FiyatAlaniAciklamaDivAciklama'>
                  {t('GBIT will gain')}
                </div>
              )}
            </div>
            <div className='H-FiyatAlaniAciklamaDiv'>
              <div className='H-FiyatAlaniAciklamaDivButton' onClick={()=> {YaziAc(5)}}>
                <h3>{t('Where Can GBIT')}</h3><span>{SeciliAlan === 5 ? '∧' : 'V' }</span>
              </div>
              {SeciliAlan === 5 && (
                <div className='H-FiyatAlaniAciklamaDivAciklama'>
                  {t('The GBIT coin')}
                </div>
              )}
            </div>
            <div className='H-FiyatAlaniAciklamaDiv'>
              <div className='H-FiyatAlaniAciklamaDivButton' onClick={()=> {YaziAc(6)}}>
                <h3>{t('When Will the')}</h3><span>{SeciliAlan === 6 ? '∧' : 'V' }</span>
              </div>
              {SeciliAlan === 6 && (
                <div className='H-FiyatAlaniAciklamaDivAciklama'>
                  {t('Our game Home')}
                </div>
              )}
            </div>
           </div>
          
        </div>
       
      </div>
      <div className='Tokenomics'>
        <div className='T-TokenomicsBaslik'>
          <h1>TOKONOMICS</h1>
          <h3>This subtitle emphasizes the innovative structure and application areas of the GameBIT token, reflecting your company's forward-thinking in technology and finance.</h3>
        </div>
        <div className='Tokenomics-Base'>
          <div className='Tokenomics-left'>
            <h1>Token Info</h1>
            <div className='Tokenomics-deploy'>
              <h3>Token name: <span>GameBIT</span></h3>
              <h3>Token Symbol: <span>GBIT</span></h3>
              <h3>Decimals: <span>18</span></h3>
              <h3>Decimals: <span>50,000,000,000</span></h3>
            </div>
          </div>
          <div className='Tokenomics-right'>
            <h1>Distribution of Tokens</h1>
            <div className='Tokenomics-value'>
              <img className='Tokenomics-Pasta' src="pastaResim.png" alt="pasta" />
              <div className='Tokenomics-Content'>
                <h3><span></span>7% Public Sales</h3>
                <h3><span></span>5% Private Sales</h3>
                <h3><span></span>20% Marketing</h3>
                <h3><span></span>15% Team and Advisors</h3>
                <h3><span></span>40% Exchange Reserve</h3>
                <h3><span></span>3% Strategic Partners</h3>
                <h3><span></span>10% Liquidity Pool</h3>
              </div>
            </div>
           
            
          </div>
        </div>
      </div>
      <div className='H-AltBolge'>
        <div className='H-Alt'>
          <div className='H-AltGovde'>
            <h2>{t('Join Our')}</h2>
            <h4>{t('Secure your spot')}</h4>
          </div>
        </div>
        <img src="BattleRoyaleCharacter.png" alt="BattleRoyaleCharacter" />
      </div>

      {HomeInfo &&
      <div className='Home-Login-Info'>
        <div className='Home-Login-Info-content'>
        <div className='Home-Login-Info-Close' onClick={() => {setHomeInfo(false);}}><FaWindowClose /></div>
          <label className='Home-Login-Info-label'>{t('Welcome to the')}</label>
          <div className='Home-Login-Info-content-div'>
            <label>{t('Unlock the')} <strong className='Home-Login-Info-link' onClick={() => {setShowLogin(true);setRegister(false); setLogin(true);setHomeInfo(false);}}> {t('Sign in')} </strong>  {t('or')} <strong className='Home-Login-Info-link' onClick={() => {setShowLogin(true); setRegister(true); setLogin(false);setHomeInfo(false);}}>{t('Sign up')}</strong>  {t('now to')}</label>
          </div>
    
          <img src="Login-Info.PNG" alt="Login-Info" />
          <div className='Home-Login-Info-Close-Mobile' onClick={() => {setHomeInfo(false);}}>{t('Close')}</div>
        </div>
      </div>
      }
    </div>
  )
}

export default Home

/*
      <div className='Home-TotalBuy'>
        <div className='Home-Progress'>
          <h1>{t('LAST CHANCE')}</h1>
          <div className="progress-bg">
            <div className="progress-bar">
              <h3 className="raised">$12,518&nbsp;{t('Collected')} </h3>
            </div>
            <h3 className="goal">0.6%</h3>
          </div>
        </div>
      </div>
     
*/